<script setup lang="ts">
import { ref, useTemplateRef } from "vue"
import { appUser } from "../app/app-state"
import { mainMenu } from "../app/main-menu"
import {
  getCookie,
  setCookie,
  setOpacity,
  setWidth,
  staticUrl,
} from "../utils/utils"

const collapsed = ref(!!getCookie("sidebar_collapsed"))

const sidebarRef = useTemplateRef("sidebar")

async function toggle(collapse: boolean) {
  const sidebar = sidebarRef.value as HTMLDivElement
  await setOpacity(sidebar, false)
  await setWidth(sidebar, collapse ? "4rem" : "15rem")
  collapse
    ? sidebar.classList.add("collapsed")
    : sidebar.classList.remove("collapsed")
  await setOpacity(sidebar, true)
  setCookie("sidebar_collapsed", collapse, 365)
  collapsed.value = collapse
}

function getLinkClass(route: any, url: string) {
  if (
    (route.path == "/" && url == "/") ||
    (route.path.startsWith(url) && url != "/")
  )
    return "active"
  return ""
}
</script>

<template>
  <div class="d-inline-flex flex-column flex-shrink-0 sidebar" ref="sidebar">
    <ul class="nav flex-column mb-auto">
      <li class="nav-item" @click="toggle(!collapsed)">
        <a class="nav-link sidebar-toggle"><i class="fa fa-bars"></i></a>
      </li>
      <li class="nav-item">
        <router-link
          to="/"
          class="nav-link nospin"
          :class="getLinkClass($route, '/')"
        >
          <img :src="staticUrl('icons/home.png')" class="me-1 sidebar-icon" />
          <div class="nav-link-text">Главная</div>
        </router-link>
      </li>
      <template v-for="item in mainMenu">
        <template v-if="item.submenu">
          <!-- {% if not item.selected %} collapsed{% endif %} -->
          <li
            v-if="
              appUser?.has_permission(item.permission) &&
              item.permission != 'None'
            "
            class="nav-item cursor-pointer"
            data-bs-toggle="collapse"
            :data-bs-target="`#${item.text}`"
          >
            <a class="nav-link">
              <img
                v-if="item.icon"
                :src="staticUrl(item.icon)"
                class="me-1 sidebar-icon"
              />{{ " " }}
              <div class="nav-link-text">{{ item.text }}</div>
              <span class="pull-right"><i class="fa fa-angle-down"></i></span>
            </a>
          </li>
          <!-- {% if item.selected %} show{% endif %} -->
          <div :id="item.text" class="collapse">
            <ul class="nav-sub">
              <template v-for="sub in item.submenu">
                <li
                  v-if="
                    appUser?.has_permission(sub.permission) &&
                    item.permission != 'None'
                  "
                  class="nav-item cursor-pointer"
                >
                  <!-- {% if sub.selected %} active{% endif %} -->
                  <a
                    :href="sub.url"
                    class="nav-link"
                    :class="getLinkClass($route, sub.url)"
                  >
                    <img
                      v-if="sub.icon"
                      :src="staticUrl(sub.icon)"
                      class="me-1 sidebar-icon"
                    />{{ " " }}
                    <div class="nav-link-text">{{ sub.text }}</div>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </template>
        <template v-else>
          <li
            class="nav-item"
            v-if="
              appUser?.has_permission(item.permission) &&
              item.permission != 'None'
            "
          >
            <!-- {% if item.selected %} active{% endif %} -->
            <router-link
              v-if="item.managed"
              :to="item.url"
              class="nav-link nospin"
              :class="getLinkClass($route, item.url)"
            >
              <img
                v-if="item.icon"
                :src="staticUrl(item.icon)"
                class="me-1 sidebar-icon"
              />{{ " " }}
              <div class="nav-link-text">{{ item.text }}</div>
            </router-link>
            <a
              v-else
              :href="item.url"
              class="nav-link"
              :class="getLinkClass($route, item.url)"
            >
              <img
                v-if="item.icon"
                :src="staticUrl(item.icon)"
                class="me-1 sidebar-icon"
              />{{ " " }}
              <div class="nav-link-text">{{ item.text }}</div>
            </a>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<style scoped></style>
