export default class AppUser {
  id: number
  username: string
  is_impersonate: boolean
  is_superuser: boolean
  tenant: {
    name: string
  }
  permissions: string[]
  constructor(
    id = 0,
    username = "",
    is_impersonate = false,
    is_superuser = false,
    tenant = { name: "" }
  ) {
    this.id = id
    this.username = username
    this.is_impersonate = is_impersonate
    this.is_superuser = is_superuser
    this.tenant = tenant
    this.permissions = []
  }

  has_permission(permission: string) {
    if (permission === "") return true
    if (this.is_superuser) return true
    if (permission.endsWith(".")) {
      for (const user_permission of this.permissions) {
        if (user_permission.startsWith(permission)) {
          return true
        }
      }
    } else {
      if (this.permissions.includes(permission)) return true
    }
    return false
  }
  update(data: any) {
    Object.assign(this, { ...data })
  }
}
