<script setup lang="ts"></script>

<template>
  <div id="spinner-wrapper" class="w-100 text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped></style>
