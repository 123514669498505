<script setup lang="ts">
import { onMounted, Ref, ref } from "vue"
import Icon from "../../components/Icon.vue"
import Spinner from "../../components/Spinner.vue"
import Title from "../../components/Title.vue"
import apiClient from "../../utils/api-client"
import {
  daysBetween,
  formatDateTime,
  formatNumber,
  staticUrl,
} from "../../utils/utils"
import { appUser } from "../app-state"

const loading = ref(false)
const ready = ref(false)

const inwork = ref()
const inactive = ref()
const nodata = ref()
const total = ref()
const subj = ref()
const object_list: Ref<ISubject[]> = ref([])

onMounted(async () => {
  loading.value = true
  const response = await apiClient.get("/posts/")
  inwork.value = response.data.inwork
  inactive.value = response.data.inactive
  nodata.value = response.data.nodata
  total.value = response.data.total
  subj.value = response.data.subj
  object_list.value = response.data.object_list
  loading.value = false
  ready.value = true
})

function statusColor(obj: IPostsView) {
  if (obj.state === null) return "border-dark bg-secondary"
  if (obj.state === 1) return "border-success"
  return "border-gray"
}

function statusStr(obj: IPostsView) {
  if (obj.state === null) return "нет данных"
  if (obj.state == 1) return "в работе"
  return "завершена/переезд"
}

function syncClr(self: IPostsView) {
  const delta = daysBetween(new Date(), self.sync_date)
  if (delta <= 1) return "text-success"
  else if (delta > 1 && delta <= 7) return "text-warning"
  else return "text-danger"
}
function dmr(post: IPostsView) {
  if (post.number)
    return (
      "DMR # " + post.number + " (" + formatDateTime(post.date_rep, true) + ")"
    )

  return ""
}

function getPartner(post: IPostsView) {
  const subject = object_list.value.find((o) => o.kod === post.subj_kod_id)
  const partner = subject?.postsview_set.find(
    (p) => p !== null && p.well_id === post.well_id && p.rownumber_post == 2
  )
  if (partner) {
    post.partner = partner
    return true
  }
  return false
}
</script>

<template>
  <Spinner v-if="loading" />
  <template v-if="ready">
    <Title>
      Посты
      <template #toolbar v-if="appUser?.has_permission('posts.change_post')">
        <a class="btn btn-success" href="/posts/update/"
          ><Icon glyph="pencil" /> Редактировать</a
        >
      </template>
    </Title>
    <div class="row m-2">
      <div class="col-3 p-1">
        <div
          class="text-center shadow p-2 m-1 rounded bg-success bg-gradient bg-opacity-25"
        >
          В работе
          <br />
          <span class="h3">{{ inwork }}</span>
        </div>
      </div>
      <div class="col-3 p-1">
        <div
          class="text-center shadow p-2 m-1 rounded bg-success bg-gradient bg-opacity-25"
        >
          На переезде/не активны
          <br />
          <span class="h3">{{ inactive }}</span>
        </div>
      </div>
      <div class="col-3 p-1">
        <div
          class="text-center shadow p-2 m-1 rounded bg-success bg-gradient bg-opacity-25"
        >
          Нет данных
          <br />
          <span class="h3">{{ nodata }}</span>
        </div>
      </div>
      <div class="col-3 p-1">
        <div
          class="text-center shadow p-2 m-1 rounded bg-success bg-gradient bg-opacity-25"
        >
          Всего
          <br />
          <span class="h3">{{ total }}</span>
        </div>
      </div>
    </div>
    <div class="accordion" id="accordionExample">
      <template v-for="(subj, ix) in object_list">
        <div class="accordion-item">
          <h2 class="accordion-header" id="heading{{ subj.kod }}">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + subj.kod"
              aria-expanded="true"
              :aria-controls="'collapse' + subj.kod"
            >
              {{ subj.name }} / {{ subj.custom_text }}
            </button>
          </h2>
          <div
            :id="'collapse' + subj.kod"
            class="accordion-collapse collapse"
            :class="ix == 0 ? 'show' : ''"
            :aria-labelledby="`heading${subj.kod}`"
          >
            <div class="accordion-body">
              <div class="row">
                <template v-for="post in subj.postsview_set">
                  <div
                    v-if="post.in_work && post.rownumber_post == 1"
                    style="
                      position: relative;
                      max-width: 350px;
                      min-height: 250px;
                    "
                    class="col border bold border-5 m-2 p-1"
                    :class="statusColor(post)"
                  >
                    <small
                      class="fw-bold"
                      style="position: absolute; right: 5px; font-size: 0.9em"
                      >{{ statusStr(post) }}</small
                    >
                    <!--  карточка поста  -->
                    <table width="350px" style="max-width: 333px">
                      <tbody>
                        <tr>
                          <!--  фотки работников  -->
                          <td class="col-3 border-end border-2" valign="top">
                            <img
                              v-if="!post.photo_url.name"
                              width="70px"
                              :src="staticUrl('icons/user.png')"
                              class="no-photo"
                              style="width: 70px"
                            />
                            <img
                              v-else-if="
                                post.photo_url.name?.includes('omac-int.com')
                              "
                              class="post_image"
                              img
                              :src="`https://${post.photo_url.name}`"
                              alt=""
                              width="70px"
                            />
                            <img
                              v-else
                              class="post_image"
                              img
                              :src="post.photo_url.url"
                              alt=""
                              width="70px"
                            />
                            <template v-if="getPartner(post)">
                              <img
                                v-if="!post.partner?.photo_url.name"
                                :src="staticUrl('icons/user.png')"
                                class="no-photo"
                                style="width: 70px"
                              />
                              <img
                                v-else-if="
                                  post.partner.photo_url.name?.includes(
                                    'omac-int.com'
                                  )
                                "
                                class="post_image"
                                img
                                :src="`https://${post.partner.photo_url.name}`"
                                alt=""
                                width="70px"
                              />
                              <img
                                v-else
                                class="post_image"
                                img
                                :src="post.partner.photo_url.url"
                                alt=""
                                width="70px"
                              />
                            </template>
                          </td>
                          <!-- Информация о посте -->
                          <td
                            class="col-9"
                            style="padding-left: 10px"
                            valign="top"
                          >
                            <small>
                              <p class="fw-bold my-0">{{ post.post_name }}</p>
                              <a
                                v-if="post.well_id"
                                :href="`/wells/${post.well_id}/`"
                                >{{ post.name }}</a
                              >
                              <hr class="mx-0 my-1" />
                              <template v-if="post.fio">
                                {{ post.fio }}
                              </template>
                              <template v-if="getPartner(post)">
                                / {{ post.partner?.fio }}
                              </template>
                              <br />
                              <template v-if="post.pers_tel">
                                {{ post.pers_tel }}
                                <br />
                              </template>
                              <template v-if="post.email">
                                {{ post.email }}
                                <br />
                              </template>
                              <template v-if="post.sync_date">
                                Синхр.:
                                <span :class="syncClr(post)">{{
                                  formatDateTime(post.sync_date, true)
                                }}</span>
                                <br />
                              </template>
                              <template v-if="post.number !== null">
                                <a
                                  :href="`/omacpro/rep_list/rep/${post.kod_current_rep}/`"
                                  >{{ dmr(post) }}</a
                                >
                                <br />
                              </template>
                              <template
                                v-if="post.length_plan && post.length_plan >= 0"
                              >
                                Заб. факт/план:
                                {{
                                  post.depth &&
                                  formatNumber(post.depth, 0, false)
                                }}/{{
                                  post.length_plan &&
                                  formatNumber(post.length_plan, 0, false)
                                }}
                              </template>
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p class="border-top border-2 m-0">
                      {{ post.pactivity || "-" }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </template>
</template>

<style scoped></style>
