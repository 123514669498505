<script setup lang="ts">
import { onMounted, onUpdated, ref } from "vue"
import ChemieShortage from "../../components/ChemieShortage.vue"
import Spinner from "../../components/Spinner.vue"
import Title from "../../components/Title.vue"
import apiClient from "../../utils/api-client"
import { formatNumber, staticUrl } from "../../utils/utils"
import { appUser } from "../app-state"
import dashboardCharts from "./dashboard"

const loading = ref(true)

const total = ref()
const in_progress = ref()
const on_close = ref()
const completed_current = ref()
const completed_total = ref()
const runs_today = ref()
const runs_this_week = ref()
const runs_this_month = ref()
const tables = ref()

onMounted(async () => {
  loading.value = true
  const response = await apiClient.get("/")
  total.value = response.data.total
  in_progress.value = response.data.in_progress
  on_close.value = response.data.on_close
  completed_current.value = response.data.completed_current
  completed_total.value = response.data.completed_total
  runs_today.value = response.data.runs_today
  runs_this_week.value = response.data.runs_this_week
  runs_this_month.value = response.data.runs_this_month
  tables.value = response.data.tables
  loading.value = false
})

onUpdated(() => {
  if (document.getElementById("in_progress"))
    dashboardCharts(
      total.value,
      in_progress.value,
      on_close.value,
      completed_current.value,
      completed_total.value
    )
})
</script>

<template>
  <Spinner v-if="loading" />
  <template v-else>
    <Title>Аналитика</Title>
    <div class="row justify-content-md-center">
      <div class="col-2">
        <div class="chart-container position-relative">
          <div class="text-center">В работе</div>
          <a href="/wells/?etap=2">
            <canvas id="in_progress"></canvas>
          </a>
          <div class="doughnut-chart-label">{{ in_progress }}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="chart-container position-relative">
          <div class="text-center">На закрытии</div>
          <a href="/wells/?etap=3">
            <canvas id="on_close"></canvas>
          </a>
          <div class="doughnut-chart-label">{{ on_close }}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="chart-container position-relative">
          <div class="text-center">Завершено(тек.)</div>
          <a
            href="/wells/?etap=4&month={{current_month}}&year={{current_year}}"
          >
            <canvas id="completed_current"></canvas
          ></a>
          <div class="doughnut-chart-label">{{ completed_current }}</div>
        </div>
      </div>
      <div class="col-2">
        <div class="chart-container position-relative">
          <div class="text-center">Завершено(всего)</div>
          <a href="/wells/?etap=4"> <canvas id="completed_total"></canvas></a>
          <div class="doughnut-chart-label">{{ completed_total }}</div>
        </div>
      </div>
    </div>
    <hr />
    <h3>Рейсы</h3>
    <div class="row justify-content-md-center">
      <div class="col-2 p-4">
        <img class="icon" :src="staticUrl('images/truck.png')" />
      </div>
      <div class="col-2 d-flex align-items-center justify-content-center">
        <div class="text-center">
          <h3>{{ runs_today }}</h3>
          Рейсов сегодня
        </div>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-center">
        <div class="text-center">
          <h3>{{ runs_this_week }}</h3>
          Рейсов на этой неделе
        </div>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-center">
        <div class="text-center">
          <h3>{{ runs_this_month }}</h3>
          Рейсов в этом месяце
        </div>
      </div>
    </div>
    <hr />
    <template
      v-if="appUser?.has_permission('warehouse.view_warehousebalancemin')"
    >
      <h3>Заканчивающаяся химия</h3>
      <div class="position-relative text-center">
        <ChemieShortage />
      </div>
      <hr />
    </template>
    <template v-if="tables">
      <h3>Объем работ по скважинам</h3>
      <table class="table table-hover">
        <thead>
          <tr class="sticky-row">
            <th>Договор</th>
            <th>В работе</th>
            <th>На закрытии</th>
            <th>Завершено(тек)</th>
            <th>Завершено(всего)</th>
          </tr>
        </thead>
        <tbody v-for="table in tables">
          <tr class="table-secondary sticky-row top-25rem">
            <td colspan="5" class="text-center fw-bold">{{ table.name }}</td>
          </tr>
          <tr v-for="(row, index) in table.table" :key="index">
            <td>{{ row.contract }}</td>
            <td>{{ formatNumber(row.in_progress, 0) }}</td>
            <td>{{ formatNumber(row.on_close, 0) }}</td>
            <td>{{ formatNumber(row.completed_current, 0) }}</td>
            <td>{{ formatNumber(row.completed_total, 0) }}</td>
          </tr>
        </tbody>
      </table>
      <hr />
    </template>
  </template>
</template>

<style scoped></style>
