export async function getModel(model_name: string) {
  const module = await import("../app/models")
  return getParameterCaseInsensitive(module, model_name!)
}

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1)
}

export function removeSpaces(s: string) {
  return s.replace(/\s/g, "")
}

export function snakeToPascal(str: string) {
  return str
    .split("_")
    .map(function (elem) {
      return elem.slice(0, 1).toUpperCase() + elem.slice(1)
    })
    .join("")
}

export function getParameterCaseInsensitive(object: any, key: string) {
  const asLowercase = key.toLowerCase()
  return object[
    Object.keys(object).filter(function (k) {
      return k.toLowerCase() === asLowercase
    })[0]
  ]
}

export function formData(form: HTMLFormElement) {
  const data: any = {}
  Object.values(form.elements).map((element: any) => {
    const name = element.name
    if (name) {
      if (element.type === "checkbox") {
        data[name] = element.checked
      } else if (element.type === "select-multiple") {
        data[name] = Array.from(element.selectedOptions).map(
          (option: any) => option.value
        )
      } else {
        data[name] = element.value
      }
    }
  })
  return data
}

export function checkboxesForm(form: HTMLFormElement) {
  const data: any = {}
  Object.values(form.elements).map((element: any) => {
    const name = element.name
    if (element.name && element.type == "checkbox") {
      if (!(name in data)) data[name] = []
      if (element.checked) data[name].push(element.value)
    }
  })
  return data
}

export function setCookie(name: string, value: any, days: number) {
  var expires = ""
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}
export function getCookie(name: string) {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function setOpacity(element: HTMLElement, opaque: boolean) {
  return element.animate(
    { opacity: opaque ? 1 : 0 },
    { duration: 250, fill: "forwards" }
  ).finished
}

export function setWidth(element: HTMLElement, width: string) {
  return element.animate({ width: width }, { duration: 250, fill: "forwards" })
    .finished
}

export function formDataToObject(formData: FormData) {
  var result: any = {}
  formData.forEach((value: any, key: any) => {
    if (!Reflect.has(result, key)) {
      result[key] = value
      return
    }
    if (!Array.isArray(result[key])) {
      result[key] = [result[key]]
    }
    result[key].push(value)
  })
  return result
}

// Needed only for TS to stop complaining
export function getModuleProp<T>(module: T, attrName: keyof T) {
  return module[attrName]
}

export function filterKeysOrdered(obj: any, keys: string[]) {
  return Object.fromEntries(keys.map((key) => [key, obj[key]]))
}

export function filterKeys(obj: any, keys: string[]) {
  return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: obj[key] })
    }, {})
}

export function excludeKeys(obj: any, keys: string[]) {
  return Object.fromEntries(
    Object.entries(obj).filter((e) => !keys.includes(e[0]))
  )
}

export function filterValues(obj: any, value: any) {
  return Object.keys(obj)
    .filter((key) => obj[key] === value)
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: obj[key] })
    }, {})
}

export function filterEmpty(obj: any) {
  return Object.keys(obj)
    .filter(
      (key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== ""
    )
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: obj[key] })
    }, {})
}

export function clearProps(obj: any) {
  return Object.fromEntries(Object.keys(obj).map((key) => [key, ""]))
}

export function modelPermission(model: Model, action: string) {
  return `${model.app_label}.${action}_${model.model_name}`
}

export function getDescendantProp(obj: any, desc: string) {
  return desc.split(".").reduce((a, b) => a[b], obj)
}

export function formatDateTime(datetime: string, dateOnly = false) {
  const parsed = new Date(Date.parse(datetime))
  if (parsed.toLocaleString() === "Invalid Date") return ""
  const dateOnlyFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
  const dateTimeFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }
  return parsed.toLocaleString(
    "ru-kz",
    dateOnly ? dateOnlyFormat : dateTimeFormat
  )
}

export function formatNumber(
  value: number | string,
  maximumFractionDigits = 2,
  omitZero = true
) {
  if (typeof value === "string") {
    value = Math.round(Number.parseFloat(value))
  }
  if (!value && omitZero) {
    return ""
  }
  return value.toLocaleString("ru", {
    maximumFractionDigits: maximumFractionDigits,
    useGrouping: false,
  })
}

export function staticUrl(path: string) {
  return `${import.meta.env.VITE_STATIC_URL}/${path}`
}

export function daysBetween(date1: Date, date2: Date | string) {
  let parsed: Date
  if (typeof date2 === "string") parsed = new Date(Date.parse(date2))
  else parsed = date2
  return Math.floor((date1.getTime() - parsed.getTime()) / (1000 * 3600 * 24))
}
