<script setup lang="ts">
import { onMounted, ref } from "vue"
import apiClient from "../utils/api-client"
import { formatNumber } from "../utils/utils"
import Spinner from "./Spinner.vue"

const loading = ref(false)
const data = ref()

onMounted(async () => {
  loading.value = true
  const response = await apiClient.get("/warehouse/chemie_shortage/htmx/")
  data.value = response.data
  loading.value = false
})

function getPercentClass(percent: number) {
  if (percent < 50) return "table-danger"
  return ""
}
</script>

<template>
  <Spinner v-if="!data" />
  <table v-else class="table table-hover">
    <thead>
      <tr class="sticky-row">
        <th>Артикул</th>
        <th>Наименование</th>
        <th>Ед.изм.</th>
        <th>Остаток на конец</th>
        <th>Необходимый минимум</th>
        <th>% от минимума</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="table in data.tables">
        <tr class="table-secondary sticky-row top-25rem">
          <td colspan="6" class="text-center fw-bold">{{ table[0] }}</td>
        </tr>
        <tr v-for="row in table[1]">
          <td>{{ row.chemie__ch_code }}</td>
          <td>{{ row.chemie__ch_name }}</td>
          <td>{{ row.chemie__unit_pack }}</td>
          <td>{{ formatNumber(row.ost_k, 0) }}</td>
          <td>{{ formatNumber(row.min_zap_skl, 0) }}</td>
          <td :class="getPercentClass((row.ost_k / row.min_zap_skl) * 100)">
            {{ formatNumber((row.ost_k / row.min_zap_skl) * 100, 2, false) }}%
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style scoped></style>
