import { createApp } from "vue"
import "../../omacweb/static/css/back-to-top.css"
import "../../omacweb/static/css/main.css"
import "../../omacweb/static/js/back-to-top"
import App from "./App.vue"
import router from "./app/router"

const app = createApp(App)
app.use(router)
app.mount("#app")
