import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Dashboard from "./views/Dashboard.vue"
import Posts from "./views/Posts.vue"

const base: RouteRecordRaw[] = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/posts/",
    component: Posts,
  },
]

const routes = base

const router = createRouter({ history: createWebHistory(), routes })

export default router
