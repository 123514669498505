export default function dashboardCharts(total, in_progress, on_close, completed_current, completed_total) {
  const options = {
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  var ctx = document.getElementById('in_progress').getContext('2d');
  new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [in_progress, total - in_progress],
        backgroundColor: ['#e64d3e', '#eee',],
        borderColor: ['#e64d3e', '#ddd',],
        borderWidth: 1
      }]
    },
    options: options,
  });

  ctx = document.getElementById('on_close').getContext('2d');
  new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [on_close, total - on_close],
        backgroundColor: ['#374a5e', '#eee',],
        borderColor: ['#374a5e', '#ddd',],
        borderWidth: 1
      }]
    },
    options: options,
  });

  ctx = document.getElementById('completed_current').getContext('2d');
  new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [completed_current, total - completed_current],
        backgroundColor: ['#a3c43d', '#eee',],
        borderColor: ['#a3c43d', '#ddd',],
        borderWidth: 1
      }]
    },
    options: options,
  });

  ctx = document.getElementById('completed_total').getContext('2d');
  new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [completed_total, total - completed_total],
        backgroundColor: ['#f1a138', '#eee',],
        borderColor: ['#f1a138', '#ddd',],
        borderWidth: 1
      }]
    },
    options: options,
  });
}