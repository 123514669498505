<script setup lang="ts"></script>

<template>
  <div class="d-flex justify-content-between border-bottom mb-3">
    <h4><slot></slot></h4>
    <div class="btn-toolbar mb-2 btn-group">
      <slot name="toolbar"></slot>
    </div>
  </div>
</template>
