<script setup lang="ts"></script>

<template>
  <div class="page-loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped></style>
