import router from "../app/router"

class ApiClient {
  csrfToken: string

  constructor() {
    this.csrfToken = ""
  }

  private async request(
    url: string,
    method: "GET" | "POST" | "OPTIONS" | "DELETE" | "PUT" | "PATCH",
    data?: any
  ) {
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: this.getHeaders(),
    })
    const responseData = await response.json()

    if (response.status === 300) {
      router.push("/companies/company/pick")
      return { status: 300, data: {} }
    }

    if (response.status === 401) {
      // router.push("/accounts/login")
      window.location.href = "/accounts/login/"
      return { status: 401, data: {} }
    }

    if (response.status === 204) {
      return { status: 204, data: {} }
    }

    if (!response.ok && ![400, 409].includes(response.status))
      throw { message: responseData || response.statusText, name: "Error" }

    if (responseData && responseData.hasOwnProperty("csrf_token"))
      this.csrfToken = responseData.csrf_token
    return {
      status: response.status,
      data: responseData,
    }
    // .catch((e) => {
    //   if (e.message === "Unauthorized") {
    //     // router.push("/accounts/login/")
    //     return { status: 401, data: {} }
    //   }
    //   if (e.message === "Forbidden") throw e
    //   return { status: 403, data: {} }
    // })
  }

  getHeaders() {
    return {
      "Accept-Language": "ru",
      "X-CSRFToken": this.csrfToken,
      "Content-Type": "application/json",
    }
  }

  get(
    url: string,
    params?: any
  ): Promise<{
    status: number
    data: any
  }> {
    return this.request(
      "/api" + url + (params ? "?" + new URLSearchParams(params) : ""),
      "GET"
    )
  }

  post(url: string, data: object, params?: URLSearchParams) {
    return this.request(
      "/api" + url + (params ? "?" + new URLSearchParams(params) : ""),
      "POST",
      data
    )
  }

  put(url: string, data: object, params?: URLSearchParams) {
    return this.request(
      "/api" + url + (params ? "?" + new URLSearchParams(params) : ""),
      "PUT",
      data
    )
  }

  patch(url: string, data: object, params?: URLSearchParams) {
    return this.request(
      "/api" + url + (params ? "?" + new URLSearchParams(params) : ""),
      "PATCH",
      data
    )
  }

  options(url: string) {
    return this.request("/api" + url, "OPTIONS")
  }

  delete(url: string, data?: any) {
    return this.request("/api" + url, "DELETE", data)
  }

  async login(data: any) {
    const response = await this.post("/accounts/login/", data)
    return response
  }

  async logout() {
    return await this.get("/accounts/logout/")
  }

  async getList<T>(url: string, params?: any): Promise<ApiListResponse<T>> {
    return await this.get(url, params)
  }

  async getAuto(url: string, params?: any): Promise<APIAutoResponse> {
    return await this.get(url, params)
  }

  async getForm<T>(url: string, params?: any): Promise<APIFormResponse<T>> {
    return await this.get(url, params)
  }
}

const apiClient = new ApiClient()

export default apiClient
