<script setup lang="ts">
import { onMounted } from "vue"
import { appUser, ready } from "./app/app-state.ts"
import Bottombar from "./components/Bottombar.vue"
import PageLoading from "./components/PageLoading.vue"
import Sidebar from "./components/Sidebar.vue"
import Topbar from "./components/Topbar.vue"
import apiClient from "./utils/api-client.ts"
import AppUser from "./utils/app-user.ts"

onMounted(async () => {
  const response = await apiClient.get("/accounts/profile/")
  appUser.value = new AppUser()
  Object.assign(appUser.value, { ...response.data.user })
  ready.value = true
})
</script>

<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <Sidebar />
      <div class="d-inline-flex flex-column flex-grow-1">
        <div class="container-fluid">
          <Topbar />
          <PageLoading />
          <div class="page-content">
            <!-- head_btn_toolbar block -->
            <div class="btn-toolbar mb-2 btn-group"></div>
            <router-view></router-view>
          </div>
        </div>
        <footer class="footer mt-auto py-3 bg-light">
          <Bottombar />
        </footer>
      </div>
    </div>
    <a class="btn btn-floating" id="btn-back-to-top"
      ><i class="fa fa-arrow-up"></i
    ></a>
  </div>
</template>

<style scoped></style>
